import { type LocaleObject } from '@nuxtjs/i18n';
import { type RouteLocationRaw } from 'vue-router';
import type enJson from '../locales/en.json';

export type Locale = typeof enJson;

export const locales: LocaleObject[] = [
  {
    code: 'en',
    dir: 'ltr',
    file: 'en.json',
    isCatchallLocale: true,
    iso: 'en-US',
    name: 'English',
  },
  {
    code: 'fi',
    dir: 'ltr',
    file: 'fi.json',
    iso: 'fi-FI',
    name: 'Suomi',
  },
  {
    code: 'vi',
    dir: 'ltr',
    file: 'vi.json',
    iso: 'vi-VN',
    name: 'Tiếng Việt',
  },
];

export default function useNuxtI18n() {
  const { $logger } = useNuxtApp();
  const { locale: localeCode, setLocale: setI18nLocale } = useI18n();
  const route = useRoute();
  const router = useRouter();

  /** Current locale. */
  const locale = computed<LocaleObject | undefined>(() =>
    locales.find((l) => l.code === localeCode.value)
  );

  /** Initializes I18n language. */
  async function initializeI18n(): Promise<void> {
    await setI18nLocale(localeCode.value);
  }

  /** Sets the locale based on new locale code. */
  async function setLocale(newLocaleCode: LocaleObject['code']): Promise<void> {
    $logger.info('Switching locale to:', newLocaleCode);
    await setI18nLocale(newLocaleCode);
    const to: RouteLocationRaw = {
      name: getPageName(router.currentRoute.value),
      params: route.params,
      query: route.query,
    };
    $logger.debug('Navigating to:', to);
    await navigateTo(to);
  }

  onMounted(initializeI18n);

  return { locale, localeCode, locales, setLocale };
}
