import { type VNode } from 'vue';
import { NMenu, type MenuOption } from 'naive-ui';
import TranslateIcon from '@/assets/icons/translate.svg?component';

export default defineNuxtComponent({
  name: 'LocaleSwitcher',
  setup() {
    const { t } = useI18n();
    const { logEvent } = useFirebase();
    const { $logger } = useNuxtApp();
    const { locale, locales, setLocale } = useNuxtI18n();
    const show = ref(false);

    /** Handles selection from menu. */
    const handleSelect = async (langCode: string): Promise<void> => {
      $logger.debug('Language change request:', langCode);
      const prevLocale = locale?.value;
      show.value = false;
      await setLocale(langCode);
      logEvent({
        data: {
          content_type: 'locale',
          locale: langCode,
          prev_locale: prevLocale?.code,
        },
        eventName: 'change_locale',
      });
    };

    const menuOptions: MenuOption = {
      label: t('language'),
      key: 'language',
      icon: () => <TranslateIcon class="text-black" />,
      children: locales
        .map((locale) =>
          'string' !== typeof locale
            ? { label: locale.name ?? 'name', key: locale.code }
            : { label: undefined, key: '' }
        )
        .filter((l) => l.label),
    };

    return { handleSelect, menuOptions, show, t };
  },
  render(): VNode {
    return (
      <NMenu
        collapsed={true}
        mode="horizontal"
        onUpdate:value={this.handleSelect}
        options={[this.menuOptions]}
        style={{ width: '4rem' }}
        value={this.$i18n.locale}
      />
    );
  },
});
